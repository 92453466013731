import script from "./ShiftConfig.vue?vue&type=script&lang=ts&setup=true"
export * from "./ShiftConfig.vue?vue&type=script&lang=ts&setup=true"

import "./ShiftConfig.vue?vue&type=style&index=0&id=4f912c06&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QItem,QItemSection,QBtn});
